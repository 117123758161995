import React from "react";
function HeaderLower() {
  return (
    <>
      <div className="header_lower">
        {/* CITY Furniture and Elite Rewards are proud to support the American Cancer Society to make a life changing difference. */}
        You dream. Together we give. CITY Furniture and Elite Rewards are proud
        to support organizations making a difference in our local communities.
      </div>
    </>
  );
}
export default HeaderLower;
