import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom/dist";
import secureLocalStorage from "react-secure-storage";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import Logo from "../../../Images/logo-blue.png";
import { actionCreaters } from "../../../Redux";
import { profile } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";

export default function Header() {
  const state = useSelector((state) => state.stateVals);

  const { accountant_id, admin_id, customer_id, osr_id, pavlina_id } = state;

  const [loading, setLoading] = useState(false);
  const [homeLink, setHomeLink] = useState("");
  const [adminName, setAdminName] = useState("");
  const navigate = useNavigate();
  const locat = useLocation();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const logOut = async () => {
    setLoading(true);
    if (
      locat.pathname === "/add_customer" ||
      locat.pathname === "/add_customer/"
    ) {
      await userActions.logOut("customerLogOut");
      navigate("/admin/customer", { replace: true });
    } else if (
      locat.pathname === "/account_dashboard" ||
      locat.pathname === "/account_dashboard/"
    ) {
      await userActions.logOut("accountantLogOut");
      navigate("/admin/accountant", { replace: true });
    } else if (
      locat.pathname === "/osr_dashboard" ||
      locat.pathname === "/osr_dashboard/" ||
      locat.pathname === "/view_osr_history" ||
      locat.pathname === "/view_osr_history/" ||
      locat.pathname === "/upload_osr" ||
      locat.pathname === "/upload_osr/"
    ) {
      await userActions.logOut("osrLogOut");
      navigate("/admin/osr", { replace: true });
    } else if (
      locat.pathname === "/searchcerti_pav" ||
      locat.pathname === "/searchcerti_pav/" ||
      locat.pathname === "/search_mpl_jobs" ||
      locat.pathname === "/search_mpl_jobs/" ||
      locat.pathname === "/searchcomp_pav" ||
      locat.pathname === "/searchcomp_pav/" ||
      locat.pathname === "/searchmail_pav_hard" ||
      locat.pathname === "/searchmail_pav_hard/" ||
      locat.pathname === "/searchmail_pav_virtual" ||
      locat.pathname === "/searchmail_pav_virtual/"
    ) {
      await userActions.logOut("pavlinaLogOut");
      navigate("/admin/pavlina", { replace: true });
    } else {
      await userActions.logOut("adminLogOut");
      navigate("/admin/login", { replace: true });
    }
  };

  const getProfile = async (id, apiLink) => {
    if (id) {
      try {
        const responce = await AdminListService.profileData(id, apiLink);
        if (responce.status === 200) {
          if (
            locat.pathname === "/account_dashboard" ||
            locat.pathname === "/account_dashboard/"
          ) {
            setFieldValue("name", responce.data.data.UserName);
            setFieldValue("email", responce.data.data.AccEmail);
            setAdminName(responce.data.data.AccName);
          } else if (
            locat.pathname === "/osr_dashboard" ||
            locat.pathname === "/osr_dashboard/" ||
            locat.pathname === "/view_osr_history" ||
            locat.pathname === "/view_osr_history/" ||
            locat.pathname === "/upload_osr" ||
            locat.pathname === "/upload_osr/"
          ) {
            setFieldValue("name", responce.data.data.UserName);
            setFieldValue("email", responce.data.data.OsrEmail);
            setAdminName(responce.data.data.OsrName);
          } else {
            setFieldValue("name", responce.data.data.UserName);
            setFieldValue("email", responce.data.data.Email);
            setAdminName(responce.data.data.AdminName);
          }
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (
      locat.pathname === "/add_customer" ||
      locat.pathname === "/add_customer/"
    ) {
      setHomeLink("/add_customer");

      getProfile(customer_id, "customer-profile/");
    } else if (
      locat.pathname === "/account_dashboard" ||
      locat.pathname === "/account_dashboard/"
    ) {
      setHomeLink("/account_dashboard");

      getProfile(accountant_id, "accountant-profile/");
    } else if (
      locat.pathname === "/osr_dashboard" ||
      locat.pathname === "/osr_dashboard/" ||
      locat.pathname === "/view_osr_history" ||
      locat.pathname === "/view_osr_history/" ||
      locat.pathname === "/upload_osr" ||
      locat.pathname === "/upload_osr/"
    ) {
      setHomeLink("/osr_dashboard");

      getProfile(osr_id, "osr-profile/");
    } else if (
      locat.pathname === "/searchcerti_pav" ||
      locat.pathname === "/searchcerti_pav/" ||
      locat.pathname === "/search_mpl_jobs" ||
      locat.pathname === "/search_mpl_jobs/" ||
      locat.pathname === "/searchcomp_pav" ||
      locat.pathname === "/searchcomp_pav/" ||
      locat.pathname === "/searchmail_pav_hard" ||
      locat.pathname === "/searchmail_pav_hard/" ||
      locat.pathname === "/searchmail_pav_virtual" ||
      locat.pathname === "/searchmail_pav_virtual/"
    ) {
      setHomeLink("/searchcerti_pav");

      getProfile(pavlina_id, "pavlina-profile/");
    } else {
      setHomeLink("/admin_dashboard");

      getProfile(admin_id, "admin-profile/");
    }
    secureLocalStorage.setItem("panel", "admin");
    // eslint-disable-next-line
  }, []);

  const updateAdminProfile = async (values, id, linkApi) => {
    setLoading(true);

    try {
      const responce = await AdminListService.profileUpdate(
        values,
        id,
        linkApi
      );

      if (responce.status === 200) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Updated Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordNew: "",
      passwordOld: "",
    },
    validationSchema: profile,
    onSubmit: (values) => {
      if (
        locat.pathname === "/add_customer" ||
        locat.pathname === "/add_customer/"
      ) {
        updateAdminProfile(values, customer_id, "customer-profile/");
      } else if (
        locat.pathname === "/searchcerti_pav" ||
        locat.pathname === "/searchcerti_pav/" ||
        locat.pathname === "/search_mpl_jobs" ||
        locat.pathname === "/search_mpl_jobs/" ||
        locat.pathname === "/searchcomp_pav" ||
        locat.pathname === "/searchcomp_pav/" ||
        locat.pathname === "/searchmail_pav_hard" ||
        locat.pathname === "/searchmail_pav_hard/" ||
        locat.pathname === "/searchmail_pav_virtual" ||
        locat.pathname === "/searchmail_pav_virtual/"
      ) {
        updateAdminProfile(values, pavlina_id, "pavlina-profile/");
      } else if (
        locat.pathname === "/osr_dashboard" ||
        locat.pathname === "/osr_dashboard/" ||
        locat.pathname === "/view_osr_history" ||
        locat.pathname === "/view_osr_history/" ||
        locat.pathname === "/upload_osr" ||
        locat.pathname === "/upload_osr/"
      ) {
        updateAdminProfile(values, osr_id, "osr-profile/");
      } else if (
        locat.pathname === "/account_dashboard" ||
        locat.pathname === "/account_dashboard/"
      ) {
        updateAdminProfile(values, accountant_id, "accountant-profile/");
      } else {
        updateAdminProfile(values, admin_id, "admin-profile/");
      }
    },
  });

  return (
    <>
      <header className="top-header customer-header">
        <nav className="navbar navbar-expand gap-3">
          <Link to={homeLink} className="custom-logo">
            <img src={Logo} alt="logo icon" />
          </Link>

          <div className="top-navbar-right ms-auto">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item dropdown dropdown-user-setting">
                <div className="user-changepass">
                  <div className="header-username">Hello, {adminName}</div>
                  <button
                    type="button"
                    className="change-pass-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleExtraLargeModal"
                    href="/"
                  >
                    Change Password
                  </button>
                  <button onClick={logOut} className="logout-btn">
                    Logout
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <div
        className="modal fade"
        id="exampleExtraLargeModal"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Change Information</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Account Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Account Name"
                      disabled={true}
                      name="name"
                      value={values.name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <span className="text-danger">{errors.name}</span>
                    ) : null}
                  </div>

                  <div className="col-12 col-md-4">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={values.email || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <span className="text-danger">{errors.email}</span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4">
                    <label className="form-label">Old Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Old Password"
                      name="passwordOld"
                      value={values.passwordOld || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.passwordOld && touched.passwordOld ? (
                      <span className="text-danger">{errors.passwordOld}</span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <label className="form-label">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      name="password"
                      value={values.password || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password && touched.password ? (
                      <span className="text-danger">{errors.password}</span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <label className="form-label">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                      name="passwordNew"
                      value={values.passwordNew || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.passwordNew && touched.passwordNew ? (
                      <span className="text-danger">{errors.passwordNew}</span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <div className="d-flex h-100 w-100 align-items-end">
                      <button className="btn btn-primary w-100" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
